export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Usuarios',
    route: 'Users',
    icon: 'UsersIcon',
  },
  {
    title: 'Categorías',
    route: 'Categories',
    icon: 'ListIcon',
  },
  {
    title: 'Sorteos',
    route: 'RafflesList',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Créditos',
    route: 'credits-bundle',
    icon: 'DollarSignIcon',
  },
]
